<template>
  <div class="popup-overlay" @click.self="closeForm">
    <div class="popup card shadow p-4 pt-5">
      <button class="close-btn btn btn-sm btn-outline-secondary" @click="closeForm">&times;</button>
      <div class="d-flex align-items-center justify-content-between">
        <h3 class="mb-0">Информацию для аппелляции</h3>
        <div class="reload" @click="fetchRandomData">&#8634;</div>
        <div class="d-flex gap-2">
          <select name="banType" id="banType" class="form-select w-50" v-model="banType" @change="handleBanTypeChange">
            <option value="бан ОС">ОС</option>
            <option value="бан ПП">ПП</option>
            <option value="бан общий">Общий</option>
          </select>
          <select name="lang" id="lang" class="form-select w-50" v-model="localLang" @change="changeLang">
            <option value="en">English</option>
            <option value="ua">Ukrainian</option>
            <option value="bg">Bulgarian</option>
          </select>
        </div>
      </div>
      <form @submit.prevent="submitForm">
        <div class="form-group mb-3 d-flex gap-1 align-items-center">
          <label class="text-nowrap">Contact name *</label>
          <p class="form-control-plaintext gray small">автоматически</p>
        </div>
        <div class="form-group mb-3">
          <label for="companyName">End Customer Company Name *</label>
          <input type="text" id="companyName" v-model="localFormData.companyName" class="form-control" />
          <p class="form-control-plaintext gray small">Если пусто, будет подставленно в это поле Contact name</p>
        </div>
        <div class="form-group mb-3 d-flex gap-1 align-items-center">
          <label class="text-nowrap">Contact email *</label>
          <p class="form-control-plaintext gray small">автоматически</p>
        </div>
        <div class="form-group mb-3 d-flex gap-1 align-items-center">
          <label class="text-nowrap">Email CC</label>
          <p class="form-control-plaintext gray small">автоматически</p>
        </div>
        <div :class="['form-group mb-3', { 'd-flex gap-1 align-items-center': !selectedAccount }]">
          <label for="customerId">Select a Google Ads Customer ID *</label>
          <!-- Проверяем, есть ли данные в selectedAccount -->
          <div v-if="selectedAccount">
            <input type="text" id="customerId" v-model="localFormData.customerId" class="form-control" required />
          </div>
          <div v-else>
            <p class="form-control-plaintext gray small">Первый</p>
          </div>
        </div>
        <div class="form-group mb-3">
          <label for="website">Website</label>
          <input type="text" id="website" v-model="localFormData.website" class="form-control" />
<!--          <p class="form-control-plaintext gray small">Если пусто, будет подставленно "youtube.com"</p>-->
        </div>
        <div class="form-group mb-3">
          <label for="keywords">Sample keywords from one of your campaigns *</label>
          <input type="text" id="keywords" v-model="localFormData.keywords" class="form-control" />
<!--          <p class="form-control-plaintext gray small">Если пусто, будет подставленно "video"</p>-->

        </div>
        <div class="form-group mb-3 d-flex gap-1 align-items-center">
          <label class="text-nowrap">Street address *</label>
          <p class="form-control-plaintext gray small">автоматически</p>
        </div>
        <div class="form-group mb-3 d-flex gap-1 align-items-center">
          <label class="text-nowrap">ZIP/Postal code*</label>
          <p class="form-control-plaintext gray small">автоматически</p>
        </div>
        <div class="form-group mb-3 d-flex gap-1 align-items-center">
          <label class="text-nowrap">City*</label>
          <p class="form-control-plaintext gray small">автоматически</p>
        </div>
        <div class="form-group mb-3 d-flex gap-1 align-items-center">
          <label for="billingCountry">Please select your billing country *</label>
          <p class="form-control-plaintext gray small">автоматически</p>
        </div>
        <div class="form-group mb-3 d-flex gap-1 align-items-center">
          <label for="accountType">Do you have single or multiple Google Ads account(s)? *</label>
          <p class="form-control-plaintext gray small">single</p>
        </div>
        <div class="form-group mb-3">
          <label for="advertisingBusiness">Are you advertising your own business? *</label>
          <p class="form-control-plaintext gray small">Yes</p>
        </div>
        <div class="form-group mb-3">
          <label for="whoPays">Who pays for the Google Ads account(s)? If not yourself, please explain. *</label>
          <input type="text" id="whoPays" v-model="localFormData.whoPays" class="form-control" required />
        </div>
        <div class="form-group mb-3 d-flex gap-1 align-items-center">
          <label for="paymentOption">Your Google Ads payment option *</label>
          <p class="form-control-plaintext gray small">Credit or Debit Card</p>
        </div>
        <div class="form-group mb-3">
          <label for="lastPayment">Date of last payment</label>
          <input type="date" id="lastPayment" v-model="localFormData.lastPayment" class="form-control" />
        </div>
        <div class="form-group mb-3 d-flex gap-1 align-items-center">
          <label for="businessCountries">What country(ies) does your business serve? *</label>
          <p class="form-control-plaintext gray small">Автоматически</p>
        </div>
        <div class="form-group mb-3">
          <label for="businessDescription">Please provide a brief description of your business. *</label>
          <textarea id="businessDescription" v-model="localFormData.businessDescription" class="form-control" required></textarea>
        </div>
        <div class="form-group mb-3 d-flex gap-1 align-items-center">
          <label for="contactMethod">In case we get disconnected, how can we contact you? *</label>
          <p class="form-control-plaintext gray small">Email</p>
        </div>
        <div class="form-group mb-3">
          <label for="clientAgency">Information regarding client-agency relationship</label>
          <input type="text" id="clientAgency" v-model="localFormData.clientAgency" class="form-control" />
        </div>
        <div class="form-group mb-3">
          <label for="domainOwnership">Domain ownership</label>
          <input type="text" id="domainOwnership" v-model="localFormData.domainOwnership" class="form-control" />
        </div>
        <div class="form-group mb-3">
          <label for="issueSummary">Summary of the issue *</label>
          <textarea id="issueSummary" rows="6" v-model="localFormData.issueSummary" class="form-control" required></textarea>
        </div>
        <div class="form-check mb-3">
          <label><input type="checkbox" v-model="localFormData.askFeedback" /> Google can ask for feedback about my support experience by email</label>
        </div>
        <div class="form-check mb-3">
          <label><input type="checkbox" v-model="localFormData.receiveEmailAfterClose" /> You may receive an email after the case is closed</label>
        </div>
        <button type="submit" class="btn btn-primary">Отправить</button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: ['formData', 'selectedAccount', 'selectedLang', 'initialBanType'],
  data() {
    return {
      localFormData: { ...this.formData },
      localLang: this.selectedLang,
      isSubmitting: false,
      banType: this.initialBanType || 'бан общий'
    };
  },
  methods: {
    submitForm() {
      if (this.isSubmitting) {
        console.log('Форма уже отправляется');
        return;
      }
      
      this.isSubmitting = true;
      try {
        this.$emit('submit', { ...this.localFormData });
        this.$emit('close');
      } catch (error) {
        console.error('Ошибка при отправке формы:', error);
      }
      this.isSubmitting = false;
    },
    closeForm() {
      this.isSubmitting = false;
      this.$emit('close');
    },
    fetchRandomData() {
      this.$emit('reload', this.banType);
    },
    changeLang() {
      this.$emit('updateLang', this.localLang);
      this.fetchRandomData();
    },
    handleBanTypeChange() {
      this.$emit('banTypeChange', this.banType);
      this.fetchRandomData();
    }
  },
  watch: {
    formData: {
      handler(newVal) {
        this.localFormData = { ...newVal };
      },
      deep: true,
      immediate: true
    },
    selectedLang(newLang) {
      this.localLang = newLang;
    }
  }
};

</script>


<style scoped>
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup {
  background: white;
  padding: 30px;
  border-radius: 10px;
  width: 600px;
  max-width: 100%;
  position: relative;
  height: 75vh;
  overflow-y: auto;
  padding-top: 50px;
}
.popup label {
  white-space: nowrap;
  font-size: 12px;
}
.popup input, .popup textarea {
  margin-top: 5px;
  font-size: 12px;
}
.small {
  font-weight: bold;
  color: #000;
}
.close-btn {
  background: none;
  border: none;
  font-size: 1.5rem;
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
}
</style>
